import { default as indexI7uNo56YU4Meta } from "/home/msservice/source/prod/fe/pages/about_us/index.vue?macro=true";
import { default as indexMH35FFygnoMeta } from "/home/msservice/source/prod/fe/pages/account/bank-account/index.vue?macro=true";
import { default as indexVVYTnLP45TMeta } from "/home/msservice/source/prod/fe/pages/account/bet-history/index.vue?macro=true";
import { default as indexHSlcJH9EbRMeta } from "/home/msservice/source/prod/fe/pages/account/change-password/index.vue?macro=true";
import { default as indexyITEmlJl1cMeta } from "/home/msservice/source/prod/fe/pages/account/deposit/index.vue?macro=true";
import { default as indexBeCibybZfbMeta } from "/home/msservice/source/prod/fe/pages/account/general-promotions/index.vue?macro=true";
import { default as index4HGLmyPDBuMeta } from "/home/msservice/source/prod/fe/pages/account/index.vue?macro=true";
import { default as indexYEW6gY83HoMeta } from "/home/msservice/source/prod/fe/pages/account/profile/index.vue?macro=true";
import { default as index8PXjjs9cvsMeta } from "/home/msservice/source/prod/fe/pages/account/referral/index.vue?macro=true";
import { default as indexzXTvbUJDpGMeta } from "/home/msservice/source/prod/fe/pages/account/transaction-history/index.vue?macro=true";
import { default as indexxSGknahEbmMeta } from "/home/msservice/source/prod/fe/pages/account/vip/index.vue?macro=true";
import { default as index3q3f03x5oFMeta } from "/home/msservice/source/prod/fe/pages/account/withdraw/index.vue?macro=true";
import { default as account4GpKTJBHLpMeta } from "/home/msservice/source/prod/fe/pages/account.vue?macro=true";
import { default as index6LyzdhXtSVMeta } from "/home/msservice/source/prod/fe/pages/betradar-sports/index.vue?macro=true";
import { default as index9ieK3ULli3Meta } from "/home/msservice/source/prod/fe/pages/btisports/index.vue?macro=true";
import { default as _91_91type_93_93deL2BfQGVnMeta } from "/home/msservice/source/prod/fe/pages/casino/[[type]].vue?macro=true";
import { default as casinoo8nzMaUh8cMeta } from "/home/msservice/source/prod/fe/pages/casino.vue?macro=true";
import { default as indexHrvsqTJKP8Meta } from "/home/msservice/source/prod/fe/pages/e-sports/index.vue?macro=true";
import { default as _91_91type_93_93f6spLaQ9ajMeta } from "/home/msservice/source/prod/fe/pages/games/[[type]].vue?macro=true";
import { default as digmaanZeUCcBYpgLMeta } from "/home/msservice/source/prod/fe/pages/games/digmaan.vue?macro=true";
import { default as games5ynMeaUovUMeta } from "/home/msservice/source/prod/fe/pages/games.vue?macro=true";
import { default as helpdxYI8N87EAMeta } from "/home/msservice/source/prod/fe/pages/help.vue?macro=true";
import { default as im_45sportsTjM46MbzraMeta } from "/home/msservice/source/prod/fe/pages/im-sports.vue?macro=true";
import { default as indexqvq4gIgjE8Meta } from "/home/msservice/source/prod/fe/pages/in-play/index.vue?macro=true";
import { default as index6GNn8jREuUMeta } from "/home/msservice/source/prod/fe/pages/index.vue?macro=true";
import { default as index4Rw5VOEklwMeta } from "/home/msservice/source/prod/fe/pages/ksports/index.vue?macro=true";
import { default as newsbnNQHAg9JnMeta } from "/home/msservice/source/prod/fe/pages/news.vue?macro=true";
import { default as index3UlSTNEeX6Meta } from "/home/msservice/source/prod/fe/pages/osports/index.vue?macro=true";
import { default as vipslrdchMjWyMeta } from "/home/msservice/source/prod/fe/pages/other/vip.vue?macro=true";
import { default as indexP0sLuOaAPKMeta } from "/home/msservice/source/prod/fe/pages/promotions/8-percent/index.vue?macro=true";
import { default as indexyjg5MWieYlMeta } from "/home/msservice/source/prod/fe/pages/promotions/bonus-100percent/index.vue?macro=true";
import { default as old_45indexQqXUFhFnHgMeta } from "/home/msservice/source/prod/fe/pages/promotions/bonus-100percent/old-index.vue?macro=true";
import { default as indexHHeZJICDQXMeta } from "/home/msservice/source/prod/fe/pages/promotions/bti-risk-free/index.vue?macro=true";
import { default as indextTTRcOkV2MMeta } from "/home/msservice/source/prod/fe/pages/promotions/daily-rebate/index.vue?macro=true";
import { default as indexwzOqqLBM7gMeta } from "/home/msservice/source/prod/fe/pages/promotions/index.vue?macro=true";
import { default as indexgEodALVe5sMeta } from "/home/msservice/source/prod/fe/pages/promotions/slots-rebate/index.vue?macro=true";
import { default as indexT3Saa7MqXMMeta } from "/home/msservice/source/prod/fe/pages/promotions/sports-cashback/index.vue?macro=true";
import { default as indexmQvh9CG0FuMeta } from "/home/msservice/source/prod/fe/pages/promotions/weekly-rebate/index.vue?macro=true";
import { default as promotionsYSW91BNczWMeta } from "/home/msservice/source/prod/fe/pages/promotions.vue?macro=true";
import { default as indexsdsijQE7UYMeta } from "/home/msservice/source/prod/fe/pages/saba-sports/index.vue?macro=true";
import { default as indexTA2FsA8YUHMeta } from "/home/msservice/source/prod/fe/pages/security_policy/index.vue?macro=true";
import { default as indexUrJJLL7WvCMeta } from "/home/msservice/source/prod/fe/pages/setting/index.vue?macro=true";
import { default as indexrx72GoCWynMeta } from "/home/msservice/source/prod/fe/pages/sports/index.vue?macro=true";
import { default as index3UUt9Drf3eMeta } from "/home/msservice/source/prod/fe/pages/ssports/index.vue?macro=true";
import { default as indexkO12k4AXugMeta } from "/home/msservice/source/prod/fe/pages/terms_and_conditions/index.vue?macro=true";
import { default as testIiktCgLLTIMeta } from "/home/msservice/source/prod/fe/pages/test.vue?macro=true";
import { default as indexDTmS4Lbn4vMeta } from "/home/msservice/source/prod/fe/pages/tsports/index.vue?macro=true";
import { default as indexBG3kIvkb7DMeta } from "/home/msservice/source/prod/fe/pages/virtual-sport/index.vue?macro=true";
export default [
  {
    name: "about_us",
    path: "/about_us",
    component: () => import("/home/msservice/source/prod/fe/pages/about_us/index.vue")
  },
  {
    name: account4GpKTJBHLpMeta?.name,
    path: "/account",
    meta: account4GpKTJBHLpMeta || {},
    component: () => import("/home/msservice/source/prod/fe/pages/account.vue"),
    children: [
  {
    name: "account-bank-account",
    path: "bank-account",
    meta: indexMH35FFygnoMeta || {},
    component: () => import("/home/msservice/source/prod/fe/pages/account/bank-account/index.vue")
  },
  {
    name: "account-bet-history",
    path: "bet-history",
    meta: indexVVYTnLP45TMeta || {},
    component: () => import("/home/msservice/source/prod/fe/pages/account/bet-history/index.vue")
  },
  {
    name: "account-change-password",
    path: "change-password",
    meta: indexHSlcJH9EbRMeta || {},
    component: () => import("/home/msservice/source/prod/fe/pages/account/change-password/index.vue")
  },
  {
    name: "account-deposit",
    path: "deposit",
    component: () => import("/home/msservice/source/prod/fe/pages/account/deposit/index.vue")
  },
  {
    name: "account-general-promotions",
    path: "general-promotions",
    meta: indexBeCibybZfbMeta || {},
    component: () => import("/home/msservice/source/prod/fe/pages/account/general-promotions/index.vue")
  },
  {
    name: "account",
    path: "",
    component: () => import("/home/msservice/source/prod/fe/pages/account/index.vue")
  },
  {
    name: "account-profile",
    path: "profile",
    meta: indexYEW6gY83HoMeta || {},
    component: () => import("/home/msservice/source/prod/fe/pages/account/profile/index.vue")
  },
  {
    name: "account-referral",
    path: "referral",
    meta: index8PXjjs9cvsMeta || {},
    component: () => import("/home/msservice/source/prod/fe/pages/account/referral/index.vue")
  },
  {
    name: "account-transaction-history",
    path: "transaction-history",
    meta: indexzXTvbUJDpGMeta || {},
    component: () => import("/home/msservice/source/prod/fe/pages/account/transaction-history/index.vue")
  },
  {
    name: "account-vip",
    path: "vip",
    meta: indexxSGknahEbmMeta || {},
    component: () => import("/home/msservice/source/prod/fe/pages/account/vip/index.vue")
  },
  {
    name: "account-withdraw",
    path: "withdraw",
    component: () => import("/home/msservice/source/prod/fe/pages/account/withdraw/index.vue")
  }
]
  },
  {
    name: "betradar-sports",
    path: "/betradar-sports",
    component: () => import("/home/msservice/source/prod/fe/pages/betradar-sports/index.vue")
  },
  {
    name: "btisports",
    path: "/btisports",
    meta: index9ieK3ULli3Meta || {},
    component: () => import("/home/msservice/source/prod/fe/pages/btisports/index.vue")
  },
  {
    name: "casino",
    path: "/casino",
    component: () => import("/home/msservice/source/prod/fe/pages/casino.vue"),
    children: [
  {
    name: "casino-type",
    path: ":type?",
    component: () => import("/home/msservice/source/prod/fe/pages/casino/[[type]].vue")
  }
]
  },
  {
    name: "e-sports",
    path: "/e-sports",
    component: () => import("/home/msservice/source/prod/fe/pages/e-sports/index.vue")
  },
  {
    name: "games",
    path: "/games",
    component: () => import("/home/msservice/source/prod/fe/pages/games.vue"),
    children: [
  {
    name: "games-type",
    path: ":type?",
    component: () => import("/home/msservice/source/prod/fe/pages/games/[[type]].vue")
  },
  {
    name: "games-digmaan",
    path: "digmaan",
    meta: digmaanZeUCcBYpgLMeta || {},
    component: () => import("/home/msservice/source/prod/fe/pages/games/digmaan.vue")
  }
]
  },
  {
    name: "help",
    path: "/help",
    component: () => import("/home/msservice/source/prod/fe/pages/help.vue")
  },
  {
    name: "im-sports",
    path: "/im-sports",
    meta: im_45sportsTjM46MbzraMeta || {},
    component: () => import("/home/msservice/source/prod/fe/pages/im-sports.vue")
  },
  {
    name: "in-play",
    path: "/in-play",
    meta: indexqvq4gIgjE8Meta || {},
    component: () => import("/home/msservice/source/prod/fe/pages/in-play/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/msservice/source/prod/fe/pages/index.vue")
  },
  {
    name: "ksports",
    path: "/ksports",
    meta: index4Rw5VOEklwMeta || {},
    component: () => import("/home/msservice/source/prod/fe/pages/ksports/index.vue")
  },
  {
    name: "news",
    path: "/news",
    component: () => import("/home/msservice/source/prod/fe/pages/news.vue")
  },
  {
    name: "osports",
    path: "/osports",
    meta: index3UlSTNEeX6Meta || {},
    component: () => import("/home/msservice/source/prod/fe/pages/osports/index.vue")
  },
  {
    name: "other-vip",
    path: "/other/vip",
    meta: vipslrdchMjWyMeta || {},
    component: () => import("/home/msservice/source/prod/fe/pages/other/vip.vue")
  },
  {
    name: promotionsYSW91BNczWMeta?.name,
    path: "/promotions",
    component: () => import("/home/msservice/source/prod/fe/pages/promotions.vue"),
    children: [
  {
    name: "promotions-8-percent",
    path: "8-percent",
    component: () => import("/home/msservice/source/prod/fe/pages/promotions/8-percent/index.vue")
  },
  {
    name: "promotions-bonus-100percent",
    path: "bonus-100percent",
    component: () => import("/home/msservice/source/prod/fe/pages/promotions/bonus-100percent/index.vue")
  },
  {
    name: "promotions-bonus-100percent-old-index",
    path: "bonus-100percent/old-index",
    component: () => import("/home/msservice/source/prod/fe/pages/promotions/bonus-100percent/old-index.vue")
  },
  {
    name: "promotions-bti-risk-free",
    path: "bti-risk-free",
    component: () => import("/home/msservice/source/prod/fe/pages/promotions/bti-risk-free/index.vue")
  },
  {
    name: "promotions-daily-rebate",
    path: "daily-rebate",
    component: () => import("/home/msservice/source/prod/fe/pages/promotions/daily-rebate/index.vue")
  },
  {
    name: "promotions",
    path: "",
    component: () => import("/home/msservice/source/prod/fe/pages/promotions/index.vue")
  },
  {
    name: "promotions-slots-rebate",
    path: "slots-rebate",
    component: () => import("/home/msservice/source/prod/fe/pages/promotions/slots-rebate/index.vue")
  },
  {
    name: "promotions-sports-cashback",
    path: "sports-cashback",
    component: () => import("/home/msservice/source/prod/fe/pages/promotions/sports-cashback/index.vue")
  },
  {
    name: "promotions-weekly-rebate",
    path: "weekly-rebate",
    component: () => import("/home/msservice/source/prod/fe/pages/promotions/weekly-rebate/index.vue")
  }
]
  },
  {
    name: "saba-sports",
    path: "/saba-sports",
    meta: indexsdsijQE7UYMeta || {},
    component: () => import("/home/msservice/source/prod/fe/pages/saba-sports/index.vue")
  },
  {
    name: "security_policy",
    path: "/security_policy",
    component: () => import("/home/msservice/source/prod/fe/pages/security_policy/index.vue")
  },
  {
    name: "setting",
    path: "/setting",
    component: () => import("/home/msservice/source/prod/fe/pages/setting/index.vue")
  },
  {
    name: "sports",
    path: "/sports",
    component: () => import("/home/msservice/source/prod/fe/pages/sports/index.vue")
  },
  {
    name: "ssports",
    path: "/ssports",
    meta: index3UUt9Drf3eMeta || {},
    component: () => import("/home/msservice/source/prod/fe/pages/ssports/index.vue")
  },
  {
    name: "terms_and_conditions",
    path: "/terms_and_conditions",
    component: () => import("/home/msservice/source/prod/fe/pages/terms_and_conditions/index.vue")
  },
  {
    name: "test",
    path: "/test",
    component: () => import("/home/msservice/source/prod/fe/pages/test.vue")
  },
  {
    name: "tsports",
    path: "/tsports",
    meta: indexDTmS4Lbn4vMeta || {},
    component: () => import("/home/msservice/source/prod/fe/pages/tsports/index.vue")
  },
  {
    name: "virtual-sport",
    path: "/virtual-sport",
    component: () => import("/home/msservice/source/prod/fe/pages/virtual-sport/index.vue")
  }
]