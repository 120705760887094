import { storeToRefs } from 'pinia'
import { io } from 'socket.io-client'
import { useJackpotStore, useAlertStore } from '~/stores'
// import { useUserStore } from '~/stores'

export default defineNuxtPlugin(() => {
    const socketState = ref({
        connected: false,
        showAlert: false,
    })

    const useJackpotStoreInstance = useJackpotStore()
    // const useUserStoreInstance = useUserStore()
    const useAlertStoreInstance = useAlertStore()
    const { jackpots } = storeToRefs(useJackpotStoreInstance)
    // const { isLoggedIn, balance } = storeToRefs(useUserStoreInstance)
    // const { refresh } = useUserStoreInstance

    // core function to refresh balance
    // const refreshCheckId = ref('')
    // const refershOverWS = () => {
    //     socket.emit('refresh', async (err, response) => {
    //         if (err) {
    //             stopUserCheck()
    //             // the other side did not acknowledge the event in the given delay
    //             await refresh()
    //         } else {
    //             balance.value = response
    //         }
    //     })
    // }

    // WS connection setup
    const socket = io(useRuntimeConfig().public.socketUrl, {
        reconnectionAttempts: 10, // number of attempts before close connection
        path: '/api/v1/ws',
        transports: ['websocket'], // use WebSocket
        withCredentials: true,
        autoConnect: false,
    })

    socket.on('connect', () => {
        socketState.value.connected = true
        socketState.value.showAlert = true
        // stopUserCheck()
        // if (isLoggedIn.value) {
        //     startUserCheck()
        // }
    })

    socket.on('disconnect', () => {
        socketState.value.connected = false
    })

    socket.on('connect_error', () => {
        // one time showAlert
        if (socketState.value.showAlert) {
            useAlertStoreInstance.showMessage({
                type: 'ERROR',
                message_key: 'common.offline',
            })
            socketState.value.showAlert = false
        }
        // revert to classic upgrade
        // socket.io.opts.transports = ['polling', 'websocket']
        // socket.close()
        // stopUserCheck()
        socketState.value.connected = false
    })

    socket.on('jackpotbroadcast', (payload) => {
        jackpots.value = payload
    })

    // periodically check balance over ws
    // const startUserCheck = () => {
    //     refershOverWS()
    //     refreshCheckId.value = setTimeout(
    //         startUserCheck,
    //         useRuntimeConfig().public.wsRefreshInterval
    //     )
    // }
    // const stopUserCheck = () => {
    //     clearInterval(refreshCheckId.value)
    // }
    // check balance event based
    // socket.on('msg', () => {
    //     refershOverWS()
    // })

    // watch(
    //     () => isLoggedIn.value,
    //     // eslint-disable-next-line
    //     (newVal, _) => {
    //         socket.disconnect().connect()
    //     }
    // )

    return {
        provide: {
            io: socket,
            socketState: socketState,
        },
    }
})
