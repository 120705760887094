<template>
    <Html>
        <Body class="antialiased">
            <NuxtLayout :name="layout">
                <NuxtLoadingIndicator
                    :height="5"
                    :duration="3000"
                    :throttle="400"
                />
                <NuxtPage />
            </NuxtLayout>
        </Body>
    </Html>
</template>
<script setup>
import { onMounted } from 'vue'
import { initFlowbite } from 'flowbite'
const { locale, t, loadLocaleMessages } = useI18n()
await loadLocaleMessages('en')

const langstate = useState('langstate')
locale.value = langstate.value

const brandName = useRuntimeConfig().public.BRAND_NAME
const layout = 'default'

onMounted(() => {
    initFlowbite()
})
useSeoMeta({
    description: brandName,
    ogTitle: brandName,
    ogDescription: brandName,
    ogImage: '/assets/favicon.png',
    twitterTitle: brandName,
    twitterDescription: brandName,
    twitterImage: '/assets/favicon.png',
    twitterCard: brandName,
})

useJsonld([
    {
        '@context': 'https://schema.org',
        '@type': 'Organization',
        url: 'https://fox789.net/',
        contactPoint: [
            {
                '@type': 'ContactPoint',
                telephone: t('contact.phone'),
                contactType: 'customer service',
            },
        ],
    },

    {
        '@context': 'https://schema.org',
        '@type': 'Organization',
        url: 'https://fox789.net/',
        logo: 'https://fox789.net/assets/logo.svg',
    },
    {
        '@context': 'https://schema.org/',
        '@type': 'Game',
        name: brandName,
        description:
            'เว็บพนันออนไลน์ กีฬา คาสิโน และหวยออนไลน์ ดีที่สุดในประเทศไทย',
        aggregateRating: {
            '@type': 'AggregateRating',
            ratingValue: '94',
            bestRating: '100',
            ratingCount: '130',
        },
    },
])

useHead({
    htmlAttrs: {
        lang: 'th',
    },
})
</script>
<style>
.page-enter-active,
.page-leave-active {
    transition: all 0.2s;
}

.page-enter-from,
.page-leave-to {
    opacity: 0;
    filter: blur(2px);
}
</style>
