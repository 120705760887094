export default defineNuxtPlugin(async (nuxtApp) => {
    const { $i18n } = nuxtApp
    const cookieOptions = {
        parseJSON: false,
    }

    const lang = useCookie('lang', cookieOptions).value

    useState('langstate', () => lang || $i18n.defaultLocale)

    if (lang && $i18n.locale !== lang) {
        await $i18n.setLocale(lang)
    }
})
