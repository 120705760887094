import { defineStore } from 'pinia'
import useCustomFetch from '~/composables/useCustomFetch'
import { STATUS_OK } from '~/constants/apiStatus'

export const useJackpotStore = defineStore('jackpotStore', () => {
    const fetcher = useCustomFetch()
    const runtimeConfig = useRuntimeConfig()

    // Jackpots
    const jackpots = ref([])
    const jackpotsId = ref('')

    const getJackpots = async () => {
        try {
            const { data } = await fetcher.get('slot/jackpot')
            if (data.value?.status === STATUS_OK) {
                jackpots.value = data.value.data
            }
        } catch (error) {
            console.error(error)
        }
    }
    const startGetJackpots = () => {
        jackpotsId.value = setInterval(
            () => getJackpots(),
            runtimeConfig.public.jackpotInterval
        )
    }
    const clearGetJackpots = () => {
        clearInterval(jackpotsId.value)
    }

    return {
        jackpots,
        getJackpots,
        startGetJackpots,
        clearGetJackpots,
    }
})
