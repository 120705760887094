import validate from "/home/msservice/source/prod/fe/node_modules/nuxt/dist/pages/runtime/validate.js";
import jackpots_45middleware_45global from "/home/msservice/source/prod/fe/middleware/jackpotsMiddleware.global.js";
export const globalMiddleware = [
  validate,
  jackpots_45middleware_45global
]
export const namedMiddleware = {
  auth: () => import("/home/msservice/source/prod/fe/middleware/auth.js"),
  "payment-middleware": () => import("/home/msservice/source/prod/fe/middleware/paymentMiddleware.js"),
  "sports-middleware": () => import("/home/msservice/source/prod/fe/middleware/sportsMiddleware.js")
}