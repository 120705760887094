// plugins/livechat.client.js
export default defineNuxtPlugin(() => {
    if (typeof window !== 'undefined') {
        window.__lc = window.__lc || {}
        window.__lc.license = 17774214 // Replace with your own LiveChat license number
        ;(function (n, t, c) {
            function i(n) {
                return e._h ? e._h.apply(null, n) : e._q.push(n)
            }

            var e = {
                _q: [],
                _h: null,
                _v: '2.0',
                on: function () {
                    i(['on', c.call(arguments)])
                },
                once: function () {
                    i(['once', c.call(arguments)])
                },
                off: function () {
                    i(['off', c.call(arguments)])
                },
                get: function () {
                    if (!e._h)
                        throw new Error(
                            "[LiveChatWidget] You can't use getters before load."
                        )
                    return i(['get', c.call(arguments)])
                },
                call: function () {
                    i(['call', c.call(arguments)])
                },
            }

            e.init = function () {
                var scriptElement = t.createElement('script')
                scriptElement.async = true
                scriptElement.type = 'text/javascript'
                scriptElement.src = 'https://cdn.livechatinc.com/tracking.js'
                t.head.appendChild(scriptElement)
            }

            if (!n.__lc.asyncInit) e.init()
            n.LiveChatWidget = n.LiveChatWidget || e

            // Hide the widget initially after loading
            n.LiveChatWidget.call('hide')
        })(window, document, [].slice)
    }
})
