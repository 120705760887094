const pagesWSRegEx = /(\/games.*)|(\/casino.*)|(^\/$)/

export default defineNuxtRouteMiddleware((to) => {
    const useNuxtAppInstance = useNuxtApp()
    const startWS = pagesWSRegEx.test(to.path)

    if (!import.meta.server) {
        try {
            if (startWS) {
                setTimeout(() => {
                    useNuxtAppInstance.$io.connect()
                }, 1000)
            } else {
                useNuxtAppInstance.$io.disconnect()
            }
        } catch (error) {
            console.error(error)
        }
    }
})
