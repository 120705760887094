import { useUserStore } from '~/stores'

export default defineNuxtPlugin(async (nuxtApp) => {
    const router = useRouter()
    const useUserStoreInstance = useUserStore(nuxtApp.$pinia)

    // Check if the token query parameter exists
    const token = router.currentRoute.value.query.token
    if (router.currentRoute.value.path === '/' && token) {
        try {
            // Make an API call with the token

            await useUserStoreInstance.loginByToken({
                token,
            })
        } catch (error) {
            console.error('Error verifying token:', error)
        } finally {
            // Remove the token from the URL
            const cleanedPath = {
                query: { ...router.currentRoute.value.query, token: undefined },
            }
            setTimeout(() => {
                router.push(cleanedPath)
            })
        }
    }
})
