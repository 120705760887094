import { defineNuxtPlugin } from '#app'
import posthog from 'posthog-js'
export default defineNuxtPlugin(() => {
    const runtimeConfig = useRuntimeConfig()
    const posthogClient = posthog.init(runtimeConfig.public.posthogPublicKey, {
        api_host:
            runtimeConfig.public.posthogHost || 'http://127.0.0.1:3000/ingest',
        ui_host: 'https://us.posthog.com',
        person_profiles: 'identified_only',
        capture_pageview: false, // we add manual pageview capturing below
        loaded: (posthog) => {
            if (import.meta.env.MODE === 'development') posthog.debug()
        },
    })

    // Add a method to identify users
    const identifyUser = (userId, properties) => {
        posthog.identify(userId, properties)
    }
    // Make sure that pageviews are captured with each route change
    const router = useRouter()
    router.afterEach((to) => {
        nextTick(() => {
            posthog.capture('$pageview', {
                current_url: to.fullPath,
            })
        })
    })

    return {
        provide: {
            posthog: () => posthogClient,
            identifyUser,
        },
    }
})
