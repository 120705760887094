import { useFetch } from 'nuxt/app'
import { useUserStore } from '~/stores'

export default function () {
    const useUserStoreInstance = useUserStore()
    const headers = useRequestHeaders(['cookie'])
    const nuxtApp = useNuxtApp()

    const get = async (path, queryParams = {}, ttl = 0) => {
        let start = 0
        let end = 0
        const { data, pending, error } = await useFetch(path, {
            headers,
            method: 'GET',
            key:
                path + JSON.stringify(queryParams) + nuxtApp.$i18n.locale.value,
            baseURL: '/api/v1',
            query: queryParams,
            dedupe: 'defer',
            transform(input) {
                return {
                    ...input,
                    fetchedAt: new Date(),
                }
            },
            getCachedData(key) {
                if (ttl === 0) {
                    return
                }
                const data =
                    nuxtApp.payload.data[key] || nuxtApp.static.data[key]
                // If data is not fetched yet
                if (!data) {
                    // Fetch the first time
                    return
                }
                // Is the data too old?
                const expirationDate = new Date(data.fetchedAt)
                expirationDate.setTime(expirationDate.getTime() + ttl)
                const isExpired = expirationDate.getTime() < Date.now()
                if (isExpired) {
                    // Refetch the data
                    return
                }
                return data
            },
            onRequest() {
                start = Date.now()
            },
            onResponse({ request }) {
                end = Date.now()
                let delay = end - start
                if (delay > 3000 && import.meta.server) {
                    console.info(delay, 'ms ', request)
                }
            },
        })

        if (error.value) {
            if (error?.value?.statusCode === 401 && path === '/refresh') {
                // we might show message here
                useUserStoreInstance.clearUserData()
            } else if (error?.value?.statusCode === 401) {
                useUserStoreInstance.clearUserData()
            }
            throw error
        }
        return { data, pending, error }
    }

    const getPromotion = async (path, queryParams = {}) => {
        const { data, pending, error } = await useFetch(path, {
            headers,
            method: 'GET',
            key: path,
            baseURL: '/api-promotion/v1',
            query: queryParams,
            dedupe: 'defer',
        })

        if (error.value) {
            if (error?.value?.statusCode === 401) {
                console.error('Unauthorized GET')
                await useUserStoreInstance.logout()
            }
            throw error
        }
        return { data, pending, error }
    }

    const post = async (path, body) => {
        const { data, pending, error } = await useFetch(path, {
            headers: {
                ...headers,
                'Content-Type': 'application/json',
            },
            method: 'POST',
            key: path,
            baseURL: '/api/v1',
            body: body,
        })

        if (error.value) {
            if (error?.value?.statusCode === 401) {
                console.error('Unauthorized POST')
                useUserStoreInstance.clearUserData()
            }
            throw error
        }

        return { data, pending, error }
    }
    const postPromotion = async (path, body) => {
        const { data, pending, error } = await useFetch(path, {
            headers: {
                ...headers,
                'Content-Type': 'application/json',
            },
            method: 'POST',
            key: path,
            baseURL: '/api-promotion/v1',
            body: body,
        })

        if (error.value) {
            if (error?.value?.statusCode === 401) {
                console.error('Unauthorized POST')
                useUserStoreInstance.clearUserData()
            }
            throw error
        }

        return { data, pending, error }
    }

    return {
        get,
        post,
        getPromotion,
        postPromotion,
    }
}
