<template>
    <div class="flex h-screen flex-col items-center justify-center">
        <NuxtLayout :name="layout">
            <div
                class="container my-5 flex flex-col items-center lg:my-[120px]"
            >
                <img
                    src="/assets/fox_error.png"
                    class="mb-5 w-[400px] lg:mb-10"
                />
                <div v-if="error?.statusCode == 404">
                    <h2
                        class="text-center text-[80px] font-semibold leading-[96px]"
                    >
                        404
                    </h2>
                    <p class="font-semibold lg:text-lg">
                        {{ $t('error.page_not_found') }}
                    </p>
                </div>

                <div v-else class="mx-auto mt-3 max-w-[200px]">
                    <button
                        class="relative w-full max-w-fit rounded-lg bg-bigg-yellow px-6 py-2 font-medium text-black lg:py-3"
                        @click="handleError"
                    >
                        Home
                    </button>
                </div>
            </div>
        </NuxtLayout>
    </div>
</template>

<script setup>
const { error } = defineProps({
    error: {
        type: Object,
        default: () => ({}),
    },
})
const layout = 'errordefault'

const handleError = () => clearError({ redirect: '/' })
</script>
