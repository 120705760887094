//Will be used in Common messages from API
export const STATUS_OK = 'OK'
export const OK = 'OK'
export const SHOW_MESSAGE = 'SHOW_MESSAGE'
export const NOT_FOUND = 'NOT_FOUND'
export const UNAUTHORIZED = 'Unauthorized'
export const UNKNOWN_ERROR = 'UNKNOWN_ERROR'
export const FINISHED = 'FINISHED'
export const ERROR = 'ERROR'
export const NOT_OK = 'NOT_OK'
export const SUCCESS = 'SUCCESS'
