import { defineStore } from 'pinia'

export const useAlertStore = defineStore({
    id: 'alertStore',
    state: () => ({
        type: '',
        message: '',
        alert: false,
        message_key: '',
    }),
    actions: {
        //type: SUCCESS/ERROR, message_key: Translation key
        showMessage({ type, message, message_key, autoClose = true }) {
            if (this.alert === true) {
                return
            }
            const toastInterval =
                useRuntimeConfig().public?.TOAST_AUTO_CLOSE_INTERVAL
            this.type = type
            this.message = message
            this.message_key = message_key
            this.alert = true
            if (autoClose) {
                setTimeout(() => {
                    this.clear()
                }, toastInterval || 10000)
            }
        },
        clear() {
            this.alert = false
            this.message = ''
            this.type = ''
            this.message_key = ''
        },
        showMessageSuccess(message) {
            this.showMessage({
                message: message,
                type: 'SUCCESS',
            })
        },
        showMessageError(payload) {
            const reg500 = '50[0-9]'
            const reg400 = '40[1-9]'
            const globalRegex500 = new RegExp(reg500, 'g')
            const globalRegex400 = new RegExp(reg400, 'g')
            if (globalRegex500.test(payload?.value?.statusCode)) {
                this.showMessage({
                    message_key: 'error.server_is_busy',
                    type: 'ERROR',
                })
            } else if (globalRegex400.test(payload?.value?.statusCode)) {
                this.showMessage({
                    message_key: 'error.server_is_busy',
                    type: 'ERROR',
                })
            } else if (payload?.value?.status === 'SHOW_MESSAGE') {
                this.showMessage({
                    message: payload?.value?.message,
                    type: 'ERROR',
                })
            } else if (payload?.value?.data?.data?.message) {
                this.showMessage({
                    message: payload?.value?.data?.data?.message,
                    type: 'ERROR',
                })
            } else if (payload?.value?.message) {
                this.showMessage({
                    message: payload?.value?.message,
                    type: 'ERROR',
                })
            } else {
                this.showMessage({
                    message_key: 'error.unknown',
                    type: 'ERROR',
                })
            }
        },
    },
})
