import { defineNuxtPlugin } from '#app/nuxt'
import { LazySvgoArrowRightActive, LazySvgoArrowRight, LazySvgoDate, LazySvgoExit, LazySvgoNavmobileCasino, LazySvgoNavmobileClose, LazySvgoNavmobileEsports, LazySvgoNavmobileEvents, LazySvgoNavmobileGameCards, LazySvgoNavmobileGames, LazySvgoNavmobileHome, LazySvgoNavmobileKeno, LazySvgoNavmobileKenosieutoc, LazySvgoNavmobileKenovietlott, LazySvgoNavmobileLode, LazySvgoNavmobileLode3mien, LazySvgoNavmobileLodesieutoc, LazySvgoNavmobileNumbergame1, LazySvgoNavmobileNumbergame2, LazySvgoNavmobileQuayso, LazySvgoNavmobileQuayso1, LazySvgoNavmobileQuayso2, LazySvgoNavmobileQuayso3, LazySvgoNavmobileSlots, LazySvgoNavmobileSports, LazyIcon, LazyIconCSS } from '#components'
const lazyGlobalComponents = [
  ["SvgoArrowRightActive", LazySvgoArrowRightActive],
["SvgoArrowRight", LazySvgoArrowRight],
["SvgoDate", LazySvgoDate],
["SvgoExit", LazySvgoExit],
["SvgoNavmobileCasino", LazySvgoNavmobileCasino],
["SvgoNavmobileClose", LazySvgoNavmobileClose],
["SvgoNavmobileEsports", LazySvgoNavmobileEsports],
["SvgoNavmobileEvents", LazySvgoNavmobileEvents],
["SvgoNavmobileGameCards", LazySvgoNavmobileGameCards],
["SvgoNavmobileGames", LazySvgoNavmobileGames],
["SvgoNavmobileHome", LazySvgoNavmobileHome],
["SvgoNavmobileKeno", LazySvgoNavmobileKeno],
["SvgoNavmobileKenosieutoc", LazySvgoNavmobileKenosieutoc],
["SvgoNavmobileKenovietlott", LazySvgoNavmobileKenovietlott],
["SvgoNavmobileLode", LazySvgoNavmobileLode],
["SvgoNavmobileLode3mien", LazySvgoNavmobileLode3mien],
["SvgoNavmobileLodesieutoc", LazySvgoNavmobileLodesieutoc],
["SvgoNavmobileNumbergame1", LazySvgoNavmobileNumbergame1],
["SvgoNavmobileNumbergame2", LazySvgoNavmobileNumbergame2],
["SvgoNavmobileQuayso", LazySvgoNavmobileQuayso],
["SvgoNavmobileQuayso1", LazySvgoNavmobileQuayso1],
["SvgoNavmobileQuayso2", LazySvgoNavmobileQuayso2],
["SvgoNavmobileQuayso3", LazySvgoNavmobileQuayso3],
["SvgoNavmobileSlots", LazySvgoNavmobileSlots],
["SvgoNavmobileSports", LazySvgoNavmobileSports],
["Icon", LazyIcon],
["IconCSS", LazyIconCSS],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
